<template>
    <!-- <HeroPage v-if="pageData" :data="pageData" /> -->

    <NewsLetterSignupSection v-if="newsletterData" :newsletterData="newsletterData" />
    <!-- <PageLayoutBuilder v-if="pageData" :data="pageData" /> -->
</template>

<script>
// import HeroPage from "@/components/HeroPage";

// import PageLayoutBuilder from "@/components/PageLayoutBuilder";

// @import component
import NewsLetterSignupSection from "@/components/NewsLetterSignupSection";

import {
    fetchPageBySlug,
    fetchTemplateContentList
} from "@streampac.io/chef_sp_1";

export default {
    name: "VideoCatTechnicalSkills",
    inject: ["isAuthenticated"],
    // metaInfo() {
    //     return {
    //         title:
    //             "Home | LEAD Hockey",
    //         description:
    //             "",
    //     };
    // },
    data() {
        return {
            pageData: null,
            newsletterData: true,
            footerData: null,
        };
    },
    components: {
        // HeroPage,
        NewsLetterSignupSection,
        // PageLayoutBuilder,
    },
    watch: {
        isAuthenticated() {
            this.pageData = null;
            setTimeout(() => {
                this.fetchPage();
            }, 50);
        },
    },
    created() {
        this.fetchPage();
        this.init();
    },
    methods: {
        fetchPage() {
            fetchPageBySlug(this.isAuthenticated, this.$route.meta.slug).then(
                (response) => {
                    this.pageData = response;
                }
            );
        },
        init() {
            const fetchDataParams = {
                paging: {
                    pageNumber: 1,
                    pageSize: 20,
                },
            };

            //Content blok 2 | Newsletter
            fetchTemplateContentList(
                this.isAuthenticated,
                "077fbcde-d582-487c-9cd6-ca37728f8fcb",
                fetchDataParams
            ).then((response) => {
                if (response && response.length > 0) {
                    const sortedResponse = response.sort(
                        (a, b) => a.sortOrder - b.sortOrder
                    );

                    this.newsletterData = sortedResponse;
                }
            });

            //Content blok 3 | Footer
            fetchTemplateContentList(
                this.isAuthenticated,
                "db06f89b-6869-4365-bd78-23a015f2b839",
                fetchDataParams
            ).then((response) => {
                if (response && response.length > 0) {
                    const sortedResponse = response.sort(
                        (a, b) => a.sortOrder - b.sortOrder
                    );
                    this.footerData = sortedResponse;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
